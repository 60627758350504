.home-about {
    margin: 100px auto 100px auto;
    max-width: 1080px;
    display: flex;
}

.home-left {
    justify-content: center;
    align-items: center;
    object-fit: contain;
    border: 50px solid rgb(238, 238, 238);
    padding: 15px;
    margin-bottom: 150px;
    margin-top: -50px;
}

.home-left h1 {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.home-right-pic {
    transition: 0.3s ease-in;
    margin-top: -200px;
}

.home-right-pic-fade {
    opacity: 0;
}

.home-p {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin-top: 25px;
    float: left;
}

.image-home {
    height: 700px;
    float: left;
    margin-left: 100px;
    justify-content: center;
    align-self: center;
    border: 50px solid rgb(238, 238, 238);
}

.home-p p {
    margin-top: 40px;
    margin-bottom: 100px;
    margin-right: 50px;
    text-align: center;
    font-size: 18px;
    line-height: 2rem;
}

.home-mid {
    background-image: url("../../images/3D/Ceulemans_Stefan_new_home-NSP_003.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    display: flex;
    height: 70vh;
    margin-top: -200px;
}

.home-mid-content {
    margin-top: 35vh;
    margin-left: 200px;
}

.home-mid-btn {
    margin-top: 32px;
}

@media screen and (max-width: 1000px) {
    .home-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        padding: 10px;
    }

    .home-mid-content {
        margin: auto;
    }

    .image-home {
        display: none;
    }
}

@media only screen and (max-width: 1366px) {
    .home-mid {
        background-attachment: scroll;
    }
}
