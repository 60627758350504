.gallery {
    column-count: 3;
    padding: 0 12px;
    margin-top: 12px;
}

.gallery .pics {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .pics:hover {
    filter: opacity(0.8);
}

@media (max-width: 991px) {
    .gallery {
        column-count: 2;
    }
}

@media (max-width: 480px) {
    .gallery {
        column-count: 1;
    }
}

/* modal */
.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.4s ease, visibility 0.4s ease,
        transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 9001;
}

.modal.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.modal img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modal .fa-times {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 2rem;
}
