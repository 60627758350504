.container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1120px;
    width: 90%;
    margin: 2rem auto 7rem;
    transform: translateY(50%);
}

.border {
    height: 369px;
    width: 290px;
    background: transparent;
    transition: border 1s;
    position: relative;
}
.border:hover {
    border: 1px solid white;
}

.card {
    height: 379px;
    width: 300px;
    background: grey;
    transition: background 0.8s;
    overflow: hidden;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.card-move {
    transition: all 0.5s ease-out;
    transform: translateY(0%);
}

.card0 {
    background: url("../images/pool_vertical_2.jpg") center center no-repeat;
    background-size: 300px;
}

.card0:hover {
    background: url("../images/pool_vertical_2.jpg") right center no-repeat;
    background-size: 600px;
}

.card1 {
    background: url("../images/stucco2.jpg") center center no-repeat;
    background-size: 300px;
}

.card1:hover {
    background: url("../images/stucco2.jpg") center center no-repeat;
    background-size: 600px;
}

.card2 {
    background: url("../images/pool_vertical_3.jpg") center center no-repeat;
    background-size: 300px;
}

.card2:hover {
    background: url("../images/pool_vertical_3.jpg") left top no-repeat;
    background-size: 600px;
}

.container h2 {
    color: white;
    margin: 20px;
    transition: opacity 1s;
}

a:link {
    text-decoration: none;
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .card {
        display: flex;
        margin: 1rem;
    }
}

@media only screen and (max-width: 1024px) {
    .card {
        height: 303px;
        width: 240px;
        margin: 1rem;
    }
    .border {
        height: 296px;
        width: 232px;
    }
    .container {
        display: flex;
        margin: 1rem 1rem 6rem 1rem;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        flex-flow: column;
        transform: translateY(0%);
    }
}
