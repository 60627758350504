.contact-page {
    background-color: grey;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    height: auto;
    padding: 20px;
}

.contact-form-container {
    margin: auto;
    margin-top: 100px;
    width: 50%;
    border: 3px solid black;
    padding: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
}

label {
    font-size: 20px;
    color: black;
}

/* Style inputs with type="text", select elements and textareas */
input[type="text"],
input[type="email"],
select,
textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
button[type="submit"] {
    background-color: #008da9;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
button[type="submit"]:hover {
    transition: 0.1s ease-in;
    background-color: #63dbf3;
}

@media screen and (max-width: 900px) {
    .contact-form-container {
        width: 80%;
    }
}
