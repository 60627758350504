/* @font-face {
    font-family: rantaiSignature;
    src: url("../rantai-signature-font/RantaiSignature-51vK8.ttf");
    font-weight: lighter;
} */

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    object-fit: contain;
    padding-left: 64px;
    /*paralax*/
    background: url("../images/3D/group2/Grego_Pools_001.jpg") center
        center/cover no-repeat;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.hero-container h1 {
    color: white;
    font-size: 55px;
    text-align: left;
    /* font-family: rantaiSignature; */
}

.hero-container p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
}

.hero-btns {
    margin-top: 32px;
}

hero-btns .btn {
    margin: 6px;
}

@media only screen and (max-width: 1366px) {
    .hero-container {
        background-attachment: scroll;
    }
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 30px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 20px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 80%;
    }
}
