.gallery-page {
    background-image: url("../../images/3D/group2/Grego_Pools_014.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    position: relative;
}

.gallery-page-header {
    font-size: 3rem;
    position: absolute;
    bottom: 0;
    margin-left: 200px;
}

.gallery-head {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 50px;
}

@media screen and (max-width: 1000px) {
    .gallery-page-header {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1366px) {
    .gallery-page {
        background-attachment: scroll;
    }
}
