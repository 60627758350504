.stucco {
    background-image: url("../../images/stucco.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    position: relative;
}

.stucco-header {
    font-size: 3rem;
    position: absolute;
    bottom: 0;
    margin-left: 200px;
}

.stucco-content {
    display: flex;
    margin: auto;
    max-width: 1080px;
    top: 100px;
}

.stucco-left-1 {
    justify-content: center;
    align-items: center;
    object-fit: contain;
    padding: 15px;
    margin-bottom: 150px;
    margin-top: 75px;
    border-left: 1px solid rgb(0, 0, 0);
    transform: translate(-50%, 0%);
}

.stucco-left-move {
    transition: all 1s ease-out;
    transform: translate(0%, 0%);
}

.stucco-right-1 {
    justify-content: center;
    align-items: center;
    object-fit: contain;
    padding: 15px;
    margin-bottom: 150px;
    margin-top: 75px;
    border-right: 1px solid rgb(0, 0, 0);
    transform: translate(50%, 0%);
}

.stucco-right-move {
    transition: all 1s ease-out;
    transform: translate(0%, 0%);
}

.stucco-left-1 h1 {
    margin-bottom: 20px;
    text-align: center;
}

.stucco-right-1 h1 {
    margin-bottom: 20px;
    text-align: center;
}

.image-stucco-right {
    height: 400px;
    float: left;
    margin-left: 100px;
    justify-content: center;
    align-self: center;
    border: 50px solid rgb(238, 238, 238);
}

.image-stucco-left {
    height: 400px;
    width: 600px;
    float: right;
    margin-right: 100px;
    justify-content: center;
    align-self: center;
    border: 50px solid rgb(238, 238, 238);
}

@media screen and (max-width: 1000px) {
    .stucco-header {
        margin-left: 0px;
    }
    .stucco-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        margin: 30px;
    }
    .image-stucco-left {
        margin: auto;
        border: 0px;
    }
    .image-stucco-right {
        margin: auto;
        border: 0px;
    }
}

@media only screen and (max-width: 1366px) {
    .stucco {
        background-attachment: scroll;
    }
}
