.footer-container {
    background-color: #454545;
    padding: 3rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-container > h1 {
    font-size: 32px;
}

.footer-container > h2 {
    font-size: 24px;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 16px;
    width: 300px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    margin-left: 20px;
}

.footer-link-items p {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #63dbf3;
    transition: 0.3s ease-out;
}

.contact,
.footer-about {
    margin-top: 0px;
    text-align: left;
    margin-left: 20px;
}

/*social icons*/

.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    align-items: center;
    width: 240px;
}

.social-icon-link {
    margin-right: 2rem;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 1000px) {
    .footer-links {
        padding-top: 2rem;
    }
    .footer-input {
        width: 100%;
    }
    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrapper {
        flex-direction: column;
    }
    .social-icons {
        width: 96px;
    }
}
