* {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    padding: 0;
    margin: 0;
    overflow-x: inherit;
}

.gallery-page,
.design,
.designs,
.stucco,
.contact-page {
    display: flex;
    height: 80vh;
    align-items: left;
    justify-content: left;
    font-size: 3rem;
}
